import React from "react";
import { AccessProvider } from "./src/components/SimpleAccessCheck";
import Preloader from "./src/components/Preloader";
import "./src/styles/normalize.css";
import "./src/styles/style.css";
import "./src/styles/vendor.css";
import "./static/fonts/icomoon/icomoon.css";

export const wrapRootElement = ({ element }) => (
  <AccessProvider>
    <Preloader />
    {element}
  </AccessProvider>
);
