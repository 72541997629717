import { set } from "lodash";
import React, {
  createContext,
  Dispatch,
  FormEvent,
  ReactNode,
  SetStateAction,
  useContext,
  useEffect,
  useState,
} from "react";

type Props = {};

const pw = "aW50ZXJpb3JmcmVpYnVyZw==";

type AccessContextType = {
  hasAccess: boolean;
  setHasAccess: Dispatch<SetStateAction<boolean>>;
};

export const AccessContext = createContext<AccessContextType>({
  hasAccess: false,
  setHasAccess: () => {},
});

export const AccessProvider = ({ children }: { children: ReactNode }) => {
  const [hasAccess, setHasAccess] = useState(false);
  useEffect(() => {
    const storagePw = localStorage.getItem("hasAccess");
    setHasAccess(storagePw === pw);
  }, []);
  return (
    <AccessContext.Provider value={{ hasAccess, setHasAccess }}>
      {children}
    </AccessContext.Provider>
  );
};

const SimpleAccessCheck = (props: Props) => {
  const { hasAccess, setHasAccess } = useContext(AccessContext);
  const [hasError, setHasError] = useState(false);
  const [loaded, setLoaded] = useState(false);
  const handleSubmit = (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    const elements = new FormData(event.currentTarget);
    const value = elements.get("password");
    if (typeof value === "string" && btoa(value) === pw) {
      console.log(btoa(value), pw);
      localStorage.setItem("hasAccess", btoa(value));
      setHasAccess(true);
      setHasError(false);
    } else {
      setHasError(true);
    }
  };
  useEffect(() => {
    setLoaded(true);
  }, []);

  return (
    loaded &&
    !hasAccess && (
      <form onSubmit={handleSubmit}>
        <input
          type="password"
          name="password"
          placeholder="Password"
          onChange={() => setHasError(false)}
        />
        <button>Weiter</button>
        <label style={{ position: "absolute" }}>
          {hasError ? "Falsches Password" : ""}
        </label>
      </form>
    )
  );
};

export default SimpleAccessCheck;
