import { useSpring, animated } from "@react-spring/web";
import React, { useContext, useEffect, useState } from "react";
import SimpleAccessCheck, {
  AccessContext,
} from "../SimpleAccessCheck/SimpleAccessCheck";

type Props = {
  onRest: () => void;
};

const AnimatedPreloader = ({ onRest }: Props) => {
  const { opacity } = useSpring({
    from: { opacity: 1 },
    to: { opacity: 0 },
    delay: 400,
    onRest,
  });
  return (
    <div className="preloader">
      <SimpleAccessCheck />
      <animated.div style={{ opacity }}>
        <div className="loader">
          <div className="dot"></div>
          <div className="dot"></div>
          <div className="dot"></div>
        </div>
      </animated.div>
    </div>
  );
};

const Preloader = () => {
  const [visible, set] = useState(true);
  const { hasAccess } = useContext(AccessContext);
  const handleRest = () => set(false);
  return visible || !hasAccess ? (
    <AnimatedPreloader onRest={handleRest} />
  ) : null;
};

export default Preloader;
